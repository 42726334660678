/* eslint-disable */

import { Card, CardContent, Grid, RACCheckBox, RACRadio, RACTable, RACTableCell, RACTableRow, RACTextbox, Typography } from "@rentacenter/racstrap"
// import { reversePaymentStyle } from "../../../stylesJS/reversePaymentStyle";
import { PoDetailsContext } from "./context/PoDetailsContext";
import React, { useContext, useState, useEffect } from "react";
import { reversePaymentStyle } from "./PoDetailStyles/reversePaymentStyle";
import { agreementDeliveryStyles } from "./PoDetailStyles/agreementDeliveryStyles";
import { agreementGlobalStyles } from "./PoDetailStyles/agreementGlobalStyles";
import { takePaymentStyles } from "./PoDetailStyles/makeStyles";
import { truncString } from "../../utils/utils";
// import { agreementGlobalStyles } from "../../../stylesJS/agreementGlobalStyles";
// import { takePaymentStyles } from "../../../stylesJS/makeStyles";
// import { agreementDeliveryStyles } from "../../../stylesJS/agreementDeliveryStyles";

export const ReceivedImformation = () => {

    /**CSS imports starts here */
    const revereseclass = reversePaymentStyle();
    const acimaDeliveryClasses = agreementDeliveryStyles();
    const classes = agreementGlobalStyles();
    const takepaymentclasses = takePaymentStyles();
    /**CSS imports ends here */

    /**useContext data stats here */
    const { data, setData, nonReceivedItems, setNonReceivedItems, selectedItem, setSelectedItem, selectReceivedItem, setSelectReceivedItem, setSelectedreceivedObject } = useContext(PoDetailsContext);
    /**useContext data ends here */

    /**State variables */
    /**State variables */
    const [HeaderData, setHeaderData] = useState(
        [{ "colName": "Item#", "width": "10%" },
        { "colName": "Model#", "width": "10%" },
        { "colName": "Description", "width": "10%" },
        { "colName": "Brand", "width": "10%" },
        { "colName": "Cost", "width": "10%" },
        { "colName": "Receiving Now", "width": "12%" },
        { "colName": "Comments", "width": "13%" },
        { "colName": "BOL", "width": "10%" },
        { "colName": "Status", "width": "15%" }]
    );

    useEffect(() => {
        getSelectedObject();
    }, [selectReceivedItem])

    /**Funstions starts here */
    const selectReceivedCheckBox = (e: any, nonReceivedItem: any) => {
        try {
            console.log(nonReceivedItem, e.target.checked, "This is the checkbox");
            if (e.target.checked) {
                const checkedItemSpread: any[] = [...selectReceivedItem];
                if (!checkedItemSpread.includes(nonReceivedItem.poDetailReceivedId)) {
                    checkedItemSpread.push(nonReceivedItem.poDetailReceivedId);
                }
                setSelectReceivedItem(checkedItemSpread);
                setSelectedItem([]);
            } else {
                const checkedItemSpread: any[] = [...selectReceivedItem];
                if (checkedItemSpread.includes(nonReceivedItem.poDetailReceivedId)) {
                    const newArray = checkedItemSpread.filter(item => item !== nonReceivedItem.poDetailReceivedId);
                    setSelectReceivedItem(newArray)
                }
            }
        } catch (error: any) {
            console.log(error.message);
        }
    }
    const onChangeOfSelectAllCheckBoxReceived = (e: any) => {
        try {
            if (e.target.checked) {
                const notReceived = nonReceivedItems.filter((item: { itemStatus: string; }) => item.itemStatus === "Received")
                const allItemId = notReceived.map((value: any) => {
                    return value.poDetailReceivedId
                })
                setSelectedItem([]);
                setSelectReceivedItem(allItemId)
            } else {
                setSelectReceivedItem([]);
            }
        } catch (error: any) {
            console.log(error.message);
        }
    }
    /**functions ends here */

    const getSelectedObject = () => {
        try {
            let nonReceivedItemsSpread = [...nonReceivedItems];
            let selectedReceivedItemObject: any = [];
            for (let values of nonReceivedItemsSpread) {
                if (selectReceivedItem.includes(values.poDetailReceivedId) && values.itemStatus == "Received") {
                    selectedReceivedItemObject.push(values);
                }
            }
            setSelectedreceivedObject(selectedReceivedItemObject);
        } catch (error: any) {
            console.log(error.message);
            return;
        }
    }



    /**Inner components starts here */
    const receivedDataContent = (): any => {
        return (
            <>
                <RACTableRow>
                    <RACTableCell className={classes.bold} style={{ width: "10%" }}>
                        <RACCheckBox
                            checked={
                                nonReceivedItems
                                    .filter((item: { itemStatus: string; }) => item.itemStatus === "Received")
                                    .every((item: { poDetailReceivedId: any; }) => selectReceivedItem.includes(item.poDetailReceivedId))
                            }
                            onChange={(e) => { onChangeOfSelectAllCheckBoxReceived(e) }} />
                        Item #
                    </RACTableCell>
                    {HeaderData.slice(1).map((col: any, index: number) => (
                        <RACTableCell key={index} className={classes.bold} style={
                            { "textAlign": (col.colName == "Status") ? "center" : "left", "width": col.width }} >
                            {col.colName}
                        </RACTableCell>
                    ))}
                </RACTableRow>
                {nonReceivedItems?.map((nonReceivedItems: any, index: number) => (
                    (nonReceivedItems.itemStatus === "Received") ? <RACTableRow key={index}>
                        <RACTableCell className={classes.formLabel + " " + classes.greyFont + " " + classes.p1} style={{ "width": "10%", color: "#5A6181" }}> <RACCheckBox
                            checked={selectReceivedItem.includes(nonReceivedItems.poDetailReceivedId)}
                            onChange={
                                (e) => { selectReceivedCheckBox(e, nonReceivedItems) }
                            }
                        />{nonReceivedItems.poDetailReceivedId}</RACTableCell>
                        <RACTableCell className={classes.formLabel + " " + classes.greyFont + " " + classes.p1} style={{ "width": "10%", color: "#5A6181" }}>{nonReceivedItems.model}</RACTableCell>
                        <RACTableCell className={classes.formLabel + " " + classes.greyFont + " " + classes.p1} style={{ "width": "10%", color: "#5A6181" }} title={nonReceivedItems.description ?? ''}>
                            {
                                nonReceivedItems.description ? truncString(nonReceivedItems.description, 22) : "-"
                            }
                        </RACTableCell>
                        <RACTableCell className={classes.formLabel + " " + classes.greyFont + " " + classes.p1} style={{ "width": "10%", color: "#5A6181" }}>{nonReceivedItems.brand}</RACTableCell>
                        <RACTableCell className={classes.formLabel + " " + classes.greyFont + " " + classes.p1} style={{ "width": "10%", color: "#5A6181" }}>{"$" + " " + nonReceivedItems.itemCost}</RACTableCell>
                        <RACTableCell style={{ width: "12%" }}>
                            <RACRadio
                                value="All"
                                label="All"
                                name='receivingNow'
                                disabled={nonReceivedItems['receivingNow'] !== 'All'}
                                checked={nonReceivedItems['receivingNow'] == 'All' ? true : false} />
                            <RACRadio
                                value="Partial"
                                label="Partial"
                                name="receivingNow"
                                className={classes.ms4}
                                disabled={nonReceivedItems['receivingNow'] !== 'Partial'}
                                checked={nonReceivedItems['receivingNow'] == 'Partial' ? true : false} />
                        </RACTableCell>
                        <RACTableCell className={classes.formLabel + " " + classes.greyFont + " " + classes.p1} style={{ "width": "13%", color: "#5A6181" }} title={nonReceivedItems?.comments}>{
                            (nonReceivedItems.comments) ? nonReceivedItems?.comments?.length > 22 ? nonReceivedItems?.comments?.substring(0, 22) + '...' : nonReceivedItems?.comments : "-"
                        } </RACTableCell>
                        <RACTableCell style={{ "width": "10%" }}><RACTextbox
                            maxlength={20}
                            type="text"
                            className='width-50'
                            name='BillOfLading'
                            value={nonReceivedItems.bol}
                            disabled
                        ></RACTextbox></RACTableCell>
                        <RACTableCell style={{ "textAlign": "center", width: "15%" }}>{nonReceivedItems.itemStatus}</RACTableCell>
                    </RACTableRow> : null
                ))}
            </>
        )
    }
    /**Innner components ends heree */


    return (
        <>
            {console.log(nonReceivedItems, "poDetailnonReceivedItems")}
            {(nonReceivedItems.some((item: { itemStatus: string; }) => (item.itemStatus == "Received"))) ?
                <Typography variant="h6" className={acimaDeliveryClasses.deliverytype + " " + classes.fontBold + " " + classes.ms2}
                    style={{
                        marginTop: '15px',
                        marginBottom: '10px'
                    }}>Pending Delivery Schedule Information</Typography> : null}
            {(nonReceivedItems.some((item: { itemStatus: string; }) => (item.itemStatus == "Received"))) ?
                <Grid >
                    <Card style={{
                        borderRadius: '15px',
                        height: '100',
                        marginTop: '5px',
                        marginLeft: '10px',
                        marginRight: '10px'
                    }}>
                        <CardContent className={takepaymentclasses.p0}>
                            <Grid item md={12} style={{
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                paddingTop: '10px'
                            }}>
                                <RACTable
                                    // renderTableHead={renderParentHeaderR1}
                                    className={revereseclass.agrementGridMC + " " + classes.p4}
                                    renderTableContent={receivedDataContent}
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid> : null}
        </>
    )
}