/* eslint-disable */

import {  Grid, RACCheckBox, RACTable, RACTableCell, RACTableRow, RACModalCard, RACButton } from "@rentacenter/racstrap"
import { PoDetailsContext } from "./context/PoDetailsContext";
import React, { useContext} from "react";
import { agreementGlobalStyles } from "./PoDetailStyles/agreementGlobalStyles";
import { postPoReverse } from "../../api/user";
import { reversePaymentStyle } from "./PoDetailStyles/reversePaymentStyle";
import { truncString } from "../../utils/utils";

export const ReverseInformation = () => {

    const { data, setSelectReceivedItem,isReversePoOpen, setIsReversePoOpen, reverseArray, selectedReverseItem, setSelectedReverseItem, getLtoData, setIsHiddenLoader } = useContext(PoDetailsContext);

    const classes = agreementGlobalStyles();
    const revereseclass = reversePaymentStyle();


    const reverseTable = () => {
        return (
            <>
                <RACTableRow>
                    <RACTableCell className={classes.bold} style={{ width: "10%", textAlign: "center" }}>
                        <RACCheckBox
                            checked=
                                {(reverseArray
                                    .filter((item: { itemStatus: string; }) => (item.itemStatus === "Received" || item.itemStatus === "ToDo")).length == 0) ?  false : 
                                    reverseArray
                                        .filter((item: { itemStatus: string; }) => (item.itemStatus === "Received" || item.itemStatus === "ToDo"))
                                        .every((item: { reverseId: any; }) => selectedReverseItem.includes(item.reverseId))
                                }
                            onChange={(e) => { onChangeOfSelectAllCheckBoxReverse(e) }}
                        />
                    </RACTableCell>
                    <RACTableCell className={classes.bold} style={{ width: "15%" }}>
                        Item#
                    </RACTableCell>
                    <RACTableCell className={classes.bold} style={{ width: "20%" }}>
                        Model#
                    </RACTableCell>
                    <RACTableCell className={classes.bold} style={{ width: "25%" }}>
                        Description
                    </RACTableCell >
                    <RACTableCell className={classes.bold} style={{ width: "15%" }}>
                        Brand
                    </RACTableCell>
                    <RACTableCell className={classes.bold} style={{ width: "15%", textAlign: "center" }}>
                        Status
                    </RACTableCell>
                </RACTableRow>
                {
                    reverseArray?.map((nonReceivedItems: any, index: number) => (
                        (nonReceivedItems.itemStatus === "ToDo" || nonReceivedItems.itemStatus === "Received" || nonReceivedItems.itemStatus === "Completed") ? <RACTableRow key={index} style={
                            (nonReceivedItems.itemStatus === "Completed") ?
                                { opacity: "0.5" } : {}
                        }>
                            <RACTableCell style={{ width: "10%", textAlign: "center" }}>
                                <RACCheckBox
                                    checked={selectedReverseItem.includes(nonReceivedItems.reverseId)}
                                    onChange={(e) => {
                                        selectReverseCheckBox(e, nonReceivedItems)
                                    }}
                                    disabled={nonReceivedItems.itemStatus === "Completed"}
                                />
                            </RACTableCell>
                            <RACTableCell className={classes.formLabel + " " + classes.greyFont} style={{ width: "15%", color: "#5A6181" }}>
                                {nonReceivedItems.poDetailReceivedId}
                            </RACTableCell>
                            <RACTableCell className={classes.formLabel + " " + classes.greyFont} style={{ width: "20%", color: "#5A6181" }}>
                                {nonReceivedItems.model}
                            </RACTableCell>
                            <RACTableCell className={classes.formLabel + " " + classes.greyFont} style={{ width: "25%", color: "#5A6181" }} title={nonReceivedItems.description ?? ''}>
                                {truncString(nonReceivedItems.description, 17)}
                            </RACTableCell >
                            <RACTableCell className={classes.formLabel + " " + classes.greyFont} style={{ width: "15%", color: "#5A6181" }}>
                                {nonReceivedItems.brand}
                            </RACTableCell>
                            <RACTableCell className={classes.formLabel + " " + classes.greyFont} style={{ width: "15%", textAlign: "center", color: "#5A6181" }}>
                                {(nonReceivedItems.itemStatus === "ToDo") ? "Scheduled" : (nonReceivedItems.itemStatus === "Completed") ? "Delivered" : "Received"}
                            </RACTableCell>
                        </RACTableRow> : null
                    ))
                }
            </>

        )
    }
    const reversePoGrid = () => {
        return (
            <>
                <Grid container>
                    <Grid item md={12}>
                        <RACTable
                            className={revereseclass.agrementGridMC + " " + classes.p4}
                            renderTableContent={reverseTable}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <RACButton
                            color="primary"
                            variant='contained'
                            size="medium"
                            className={classes.mt4 + " " + classes.floatRight + " " + classes.py2 + " " + classes.ms2 + " " + classes.px3 + " " + classes.me4 + " " + classes.px4}
                            onClick={() => {
                                reverseButtonClick()
                            }}
                            disabled={selectedReverseItem.length === 0}
                        >
                            Reverse
                        </RACButton>
                        <RACButton
                            color="primary"
                            variant="outlined"
                            size="medium"
                            className={classes.mt4 + " " + classes.floatRight + " " + classes.p2 + " " + classes.mx2 + " " + classes.px4 + " "}
                            onClick={() => {
                                setIsReversePoOpen(false)
                                setSelectedReverseItem([]);
                            }}
                        >
                            Cancel
                        </RACButton>
                    </Grid>
                </Grid>
            </>
        )
    }
    /**reverse button click function starts here */
    const reverseButtonClick = async () => {
        try {
            setIsReversePoOpen(false);
            setIsHiddenLoader(true);
            const array1 = reverseArray;
            const array2 = selectedReverseItem;
            const matchingItems = array1.filter(item => array2.includes(item.reverseId));
            const poDetailReceivedIds = matchingItems.map((value: any, index: number) => {
                return (
                    String(value.poDetailReceivedId)
                )
            });
            let poReversePayload = {
                purchaseOrderId: String(data?.purchaseOrderId),
                purchaseOrderReceivedId: poDetailReceivedIds,
                isCancel: false
            }

            console.log(poReversePayload,"Po service starts here")
            let apiCallForReverse = await postPoReverse(poReversePayload);
            
            const getLtoDataApiCall = await getLtoData();
            setIsHiddenLoader(false);
            setSelectReceivedItem([]);
        } catch (error) {
        }
    }
    /**reverse button click function ends here */

    /**Reverse select all checkox */
    const onChangeOfSelectAllCheckBoxReverse = (e: any) => {
        try {
            if (e.target.checked) {
                const notReceived = reverseArray.filter((item: { itemStatus: string; }) => (item.itemStatus === "Received" || item.itemStatus === "ToDo"))
                console.log(notReceived, "non reverse item")
                const allItemId = notReceived.map((value: any) => {
                    return value.reverseId
                })
                console.log(allItemId, "This is the reverseId")
                setSelectedReverseItem(allItemId)
            } else {
                setSelectedReverseItem([]);
            }
        } catch (error: any) {
            console.log(error.message);
        }
    }
    /**Reverse selecte all check box ends here */

    //select checkbox
    const selectReverseCheckBox = (e: any, nonReceivedItem: any) => {
        try {
            console.log(nonReceivedItem, e.target.checked, "This is the checkbox");
            if (e.target.checked) {
                const checkedItemSpread: any[] = [...selectedReverseItem];
                console.log(checkedItemSpread, "There is a data in the single select")
                if (!checkedItemSpread.includes(nonReceivedItem.reverseId)) {
                    checkedItemSpread.push(nonReceivedItem.reverseId);
                }
                setSelectedReverseItem(checkedItemSpread);
            } else {
                const checkedItemSpread: any[] = [...selectedReverseItem];
                if (checkedItemSpread.includes(nonReceivedItem.reverseId)) {
                    const newArray = checkedItemSpread.filter(item => item !== nonReceivedItem.reverseId);
                    setSelectedReverseItem(newArray)
                }
            }
        } catch (error: any) {
            console.log(error.message);
        }
    }

    //select single checkbox ends
    return (
        <>
            <RACModalCard
                isOpen={isReversePoOpen}
                closeIcon={true}
                title="Please select the item that you want to reverse"
                maxWidth="md"
                borderRadius={"15px"}
                onClose={() => {
                    setIsReversePoOpen(false);
                    setSelectedReverseItem([]);
                }}
                TitleClassName={classes.textCenter}
            >
                {reversePoGrid()}
            </RACModalCard>
        </>
    )
}