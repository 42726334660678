/* eslint-disable */
import { Card, CardContent, Grid, RACButton, RACCheckBox, RACModalCard, RACRadio, RACSelect, RACTable, RACTableCell, RACTableRow, RACTextbox, Typography } from "@rentacenter/racstrap"
import React, { useContext, useEffect, useState } from "react";
import { PoDetailsContext } from "../PoDetails/context/PoDetailsContext";
import { GetTransactionType } from '../../api/user'
import { agreementDeliveryStyles } from "../PoDetails/PoDetailStyles/agreementDeliveryStyles";
import { agreementGlobalStyles } from "../PoDetails/PoDetailStyles/agreementGlobalStyles";
import { reversePaymentStyle } from "../PoDetails/PoDetailStyles/reversePaymentStyle";
import { takePaymentStyles } from "../PoDetails/PoDetailStyles/makeStyles";
import { truncString } from "../../utils/utils";

export const PendingInformation = () => {

    /**CSS Imports starts here*/
    const acimaDeliveryClasses = agreementDeliveryStyles();
    const classes = agreementGlobalStyles();
    const revereseclass = reversePaymentStyle();
    const takepaymentclasses = takePaymentStyles();
    /**CSS Import ends here */

    /**State variables */
    const [HeaderData, setHeaderData] = useState(
        [{ "colName": "Item#", "width": "10%" },
        { "colName": "Model#", "width": "10%" },
        { "colName": "Description", "width": "10%" },
        { "colName": "Brand", "width": "10%" },
        { "colName": "Cost", "width": "10%" },
        { "colName": "Receiving Now", "width": "12%" },
        { "colName": "Comments", "width": "13%" },
        { "colName": "BOL", "width": "10%" },
        { "colName": "Status", "width": "15%" }]
    );
    const [partialOpen, setpartialOpen] = useState<any>(false);
    const [openedItemId, setOpenedId] = useState("");
    const [partialReasonDD, separtialReasonDD] = useState<any>([
        {
            "label": "Select Reason",
            "value": "0"
        }
    ]);
    const [reason, setReason] = useState("0");
    const [comment, setComment] = useState("");
    /**State variables ends here */

    /**useContext data stats here */
    const { data, setData, nonReceivedItems, setNonReceivedItems, selectedItem, setSelectedItem, selectReceivedItem, setSelectReceivedItem } = useContext(PoDetailsContext);
    /**useContext data ends here */

    /**useEffects starts here */
    useEffect(() => {

        if (data.itemInformation) {
            console.log(data, "InsideuseEffectif")
            setNonReceivedItems(data.itemInformation);
        }

    }, [data]);

    useEffect(() => {
        getStateFn();
    }, []);

    /*useEffects ends here */

    /**API function to get the reason for the partial popup from the service */
    const getStateFn = async () => {
        try {
            let dropDownData = await GetTransactionType("lto_po_reason_type");
            console.log(dropDownData.data.referenceDetails, "This is the data will be");
            let dropDownDataArray = [{
                "label": "Select Reason",
                "value": "0"
            }].concat(dropDownData.data.referenceDetails.map((value: any, index: number) => {
                return { label: value.description, value: value.referenceCode }
            }))
            console.log(dropDownDataArray, "this is the dropdow array");
            separtialReasonDD(dropDownDataArray);
        } catch (error: any) {
            console.log(error.message);
        }
    }

    /*
    *function starts here
     */
    //! the follwoing function will be used to change the radio button;
    const onChangeOfPendingItemFields = (e: any, nonReceivedItem: any) => {
        try {
            console.log(e, "\n")
            console.log(nonReceivedItem, "non eceived item")
            const selectedItemSpread = [...selectedItem];
            if (e?.target?.name === "BillOfLading") {
                setNonReceivedItems((prevNonReceivedItems: any) =>
                    prevNonReceivedItems.map((item: any) =>
                        item.poDetailReceivedId === nonReceivedItem.poDetailReceivedId
                            ? { ...item, bol: e?.target?.value }
                            : item
                    )
                );
                if (!selectedItemSpread.includes(nonReceivedItem.poDetailReceivedId)) {
                    if (e?.target?.value !== "") {
                        selectedItemSpread.push(nonReceivedItem.poDetailReceivedId)
                    }
                    setSelectedItem(selectedItemSpread);
                }
            } else if (e?.target?.name === "receivingNow") {
                console.log("I am called from receiving now")
                console.log("e from the ",e)
                let valueOfTheTarget = e.target.value;
                console.log("e target was founfd",e.target.value)
                console.log(valueOfTheTarget,"This is the data from the value");
                setNonReceivedItems((prevNonReceivedItems: any) =>
                    prevNonReceivedItems.map((item : any) =>
                        item.poDetailReceivedId === nonReceivedItem.poDetailReceivedId
                            ? { ...item, "receivingNow": valueOfTheTarget , "bol" : (valueOfTheTarget === "All") ? "" : item.bol   }
                            : item
                    )
                );
                if (!selectedItemSpread.includes(nonReceivedItem.poDetailReceivedId)) {
                    selectedItemSpread.push(nonReceivedItem.poDetailReceivedId)
                }
                setSelectedItem(selectedItemSpread);
                console.log("state is setted to non received items",e);
                console.info(e.target,"Target of the e");
                if(e.target){
                    if (e?.target?.value == "Partial") {
                        setOpenedId(nonReceivedItem.poDetailReceivedId);
                        setpartialOpen(true);
                    }
                }
            }
        } catch (error: any) {
            console.log(error.message, "This is the error message!!!");
        }
    }

    console.log(nonReceivedItems, "Non recived item partial")


    //Select all checkbox function;
    const onChangeOfSelectAllCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (e?.target?.checked) {
                let notReceived = nonReceivedItems.filter((item: { itemStatus: string; }) => (item.itemStatus !== "Received" && item.itemStatus !== "Charged-off"))
                let allItemId = notReceived.map((value: { poDetailReceivedId: any; }) => {
                    return value.poDetailReceivedId
                })
                setSelectReceivedItem([]);
                setSelectedItem(allItemId);
            } else {
                setSelectedItem([]);
            }
        } catch (error: any) {
            console.log(error.message);
        }
    }

    //Select checkbox
    const selectCheckBox = (e: any, nonReceivedItem: any) => {
        try {
            if (e?.target?.checked) {
                const checkedItemSpread: any[] = [...selectedItem];
                if (!checkedItemSpread.includes(nonReceivedItem.poDetailReceivedId)) {
                    checkedItemSpread.push(nonReceivedItem.poDetailReceivedId);
                }
                setSelectedItem(checkedItemSpread);
                setSelectReceivedItem([]);
            } else {
                const checkedItemSpread: any[] = [...selectedItem];
                if (checkedItemSpread.includes(nonReceivedItem.poDetailReceivedId)) {
                    const newArray = checkedItemSpread.filter(item => item !== nonReceivedItem.poDetailReceivedId);
                    setSelectedItem(newArray)
                }
            }
        } catch (error: any) {
            console.log(error.message);
        }
    }

    //! function to save the popup;
    const partialPopUpSave = () => {
        try {
            setNonReceivedItems((prevNonReceivedItems: any[]) =>
                prevNonReceivedItems.map((item: { poDetailReceivedId: string; }) =>
                    item.poDetailReceivedId == openedItemId
                        ? { ...item, "comments": comment, "partialReason": reason }
                        : item
                )
            )
            setpartialOpen(false);
            setOpenedId("");
            setComment("");
            setReason("0");
        } catch (error: any) {
            console.log(error.message);
        }
    }
    //! function to cancel the partial reason;
    const partialPopUpCancel = () => {
        setNonReceivedItems((prevNonReceivedItems: any[]) =>
            prevNonReceivedItems.map((item: { poDetailReceivedId: string; }) =>
                item.poDetailReceivedId == openedItemId
                    ? { ...item, "receivingNow": "All" }
                    : item
            )
        )
        setComment("");
        setReason("0");
        setOpenedId("");
        setpartialOpen(false);
    }
    /*
    *Functions ends here 
    */
    /**inner componants part starts here */
    //! The following function will be used to bind the pending information in the card;
    const renderParentContent = (): any => {
        return (
            <>
                <RACTableRow>
                    <RACTableCell className={classes.bold} style={{ width: "10%" }}>
                        <RACCheckBox
                            // className={classes.pe4}
                            checked={
                                nonReceivedItems
                                    .filter((item: { itemStatus: string; }) => item.itemStatus !== "Received" && item.itemStatus !== "Charged-off")
                                    .every((item: { poDetailReceivedId: any; }) => selectedItem.includes(item.poDetailReceivedId))
                            }
                            onChange={(e) => { onChangeOfSelectAllCheckBox(e) }}
                        />
                        Item #
                    </RACTableCell>
                    {HeaderData.slice(1).map((col: any, index: number) => (
                        <RACTableCell key={index} className={classes.bold} style={
                            { "textAlign": (col.colName == "Status") ? "center" : "left", "width": col.width }} >
                            {col.colName} {(col.colName == "BOL") ?<span style={{color:"red","fontWeight":"bold"}}>*</span> : null}
                        </RACTableCell>
                    ))}
                </RACTableRow>
                {nonReceivedItems?.map((nonReceivedItems: any, index: number) => (
                    (nonReceivedItems?.itemStatus != "Received" && nonReceivedItems?.itemStatus != "Charged-off") ? <RACTableRow key={index}>
                        <RACTableCell className={classes.formLabel + " " + classes.p1} style={{ "width": "10%", color: "#5A6181" }}> <RACCheckBox
                            // className={classes.pe4}
                            checked={selectedItem.includes(nonReceivedItems?.poDetailReceivedId)}
                            onChange={
                                (e) => { selectCheckBox(e, nonReceivedItems) }
                            }
                            label={(!nonReceivedItems?.isNew) ? nonReceivedItems?.poDetailReceivedId : "-"}
                        />
                            {/* <span style={{ textAlign: (!nonReceivedItems?.isNew) ? "left" : "center", display: "inline-block" , width : '100%' }}>
                            {(!nonReceivedItems?.isNew) ? nonReceivedItems?.poDetailReceivedId : "-"}
                            </span> */}
                        </RACTableCell>
                        <RACTableCell className={classes.formLabel + " " + classes.p1} style={{ "width": "10%", color: "#5A6181" }}>{nonReceivedItems?.model}</RACTableCell>
                        <RACTableCell className={classes.formLabel + " " + classes.p1} style={{ "width": "10%", color: "#5A6181" }} title={nonReceivedItems.description ?? ''}>
                            {(nonReceivedItems && nonReceivedItems.description) ? truncString(nonReceivedItems.description, 17) : "-"}
                        </RACTableCell>
                        <RACTableCell className={classes.formLabel + " " + classes.p1} style={{ "width": "10%", color: "#5A6181" }}>{nonReceivedItems?.brand}</RACTableCell>
                        <RACTableCell className={classes.formLabel + " " + classes.p1} style={{ "width": "10%", color: "#5A6181" }}>{"$" + " " + nonReceivedItems?.itemCost}</RACTableCell>
                        <RACTableCell style={{ width: "12%" }}>
                            <RACRadio
                                value="All"
                                label="All"
                                name='receivingNow'
                                onChange={(e) => {
                                    onChangeOfPendingItemFields(e, nonReceivedItems)
                                }}
                                checked={(nonReceivedItems['receivingNow'] == 'All' || nonReceivedItems['receivingNow'] == 'ALL' || nonReceivedItems['receivingNow'] == 'all') ? true : false}
                            />
                            <RACRadio
                                value="Partial"
                                label="Partial"
                                name="receivingNow"
                                onChange={(e) => {
                                    onChangeOfPendingItemFields(e, nonReceivedItems)
                                }}
                                checked={
                                    (nonReceivedItems['receivingNow'] == 'Partial' || nonReceivedItems['receivingNow'] == 'PARTIAL' || nonReceivedItems['receivingNow'] == 'partial') ? true : false}
                                className={classes.ms4} />
                        </RACTableCell>
                        <RACTableCell className={classes.formLabel + " " + classes.p1} style={{ "width": "13%", color: "#5A6181" }} title={
                            nonReceivedItems?.comments
                        }>{
                                (nonReceivedItems?.comments) ? nonReceivedItems?.comments?.length > 22 ? nonReceivedItems?.comments?.substring(0, 22) + '...' : nonReceivedItems?.comments : "-"
                            } </RACTableCell>
                        <RACTableCell style={{ "width": "10%" }}><RACTextbox
                            maxlength={20}
                            type="text"
                            className='width-50'
                            name='BillOfLading'
                            value={nonReceivedItems.bol == null ? "" : nonReceivedItems.bol}
                            OnChange={(e) => {
                                onChangeOfPendingItemFields(e, nonReceivedItems)
                            }}
                        ></RACTextbox></RACTableCell>
                        <RACTableCell style={{ "textAlign": "center", width: "15%", color: "#5A6181" }}><Typography>{nonReceivedItems?.itemStatus}</Typography></RACTableCell>
                    </RACTableRow> : null
                ))}
            </>
        )
    }
    //! the following function will be used to open the partial reason popup
    const partialReasonPopup = () => {
        return (
            <div>
                <Grid container>
                    <Grid md={12} className={classes.px3}>
                        <RACSelect
                            inputLabel="Reason"
                            options={partialReasonDD}
                            classes={{ "nativeSelect": classes.wd70 }}
                            defaultValue={reason}
                            inputLabelClassName={revereseclass.labelColorForSelect + " " + classes.pb2}
                            onChange={(e: any) => {
                                setReason(e?.target?.value)
                            }}
                        />
                        <Typography className={classes.pt2} style={{ "color": "#646B88" }}>Describe which part of the set are damaged or missing for this item</Typography>
                        <Typography className={classes.pt2 + " " + classes.pb2} style={{ "color": "#646B88" }}>Comments</Typography>
                        <textarea
                            id="Comments"
                            name="Comments"
                            value={comment}
                            style={{ resize: 'none', width: "100%", height: "100px", border: "1px solid #EAEAEA" }}
                            title='Describe which part of the set are damaged or missing for this item'
                            onChange={(e) => { setComment(e?.target?.value) }}
                        />
                        <RACButton
                            color="primary"
                            variant='contained'
                            size="small"
                            className={classes.mt4 + " " + classes.floatRight + " " + classes.py2 + " " + classes.ms2 + " " + classes.px3 + " "}
                            disabled={(!comment || reason == "0")}
                            onClick={() => {
                                partialPopUpSave();
                            }}
                        >
                            OK
                        </RACButton>
                        <RACButton
                            color="primary"
                            variant="outlined"
                            size="small"
                            className={classes.mt4 + " " + classes.floatRight + " " + classes.p2 + " " + classes.mx2 + " " + classes.px4 + " "}
                            onClick={() => {
                                partialPopUpCancel();
                            }}
                        >
                            Cancel
                        </RACButton>
                    </Grid>
                </Grid>
            </div>
        );
    }
    /**inner components starts here */
    /**Functions starts here */


    return (
        <>
            {/**Partial reason model starts here*/}
            <RACModalCard
                isOpen={partialOpen}
                maxWidth="sm"
                title="Refusal Reason"
                closeIcon={true}
                borderRadius={"15px !important"}
                children={partialReasonPopup()}
                onClose={() => { partialPopUpCancel() }}
            />
            {console.log(nonReceivedItems, "nonReceivedItemsin some")}

            {(nonReceivedItems.some((item: { itemStatus: string; }) => (item?.itemStatus == "Partially Received" || item?.itemStatus == "Pending"))) ?
                <Typography variant="h6" className={acimaDeliveryClasses.deliverytype + " " + classes.fontBold + " " + classes.ms2}
                    style={{
                        marginTop: '15px',
                        marginBottom: '10px'
                    }}>Pending Information</Typography> : null}

            {(nonReceivedItems.some((item: { itemStatus: string; }) => (item?.itemStatus == "Partially Received" || item?.itemStatus == "Pending"))) ?
                <Grid >
                    <Card
                        style={{
                            borderRadius: '15px',
                            height: '100',
                            marginTop: '5px',
                            marginLeft: '10px',
                            marginRight: '10px'
                        }}
                    >
                        <CardContent className={takepaymentclasses.p0}>
                            <Grid item md={12} style={{
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                paddingTop: '10px'
                            }}>
                                <RACTable
                                    className={revereseclass.agrementGridMC + " " + classes.p4}
                                    renderTableContent={renderParentContent}
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                : null}
        </>
    )
}