/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
const moment = require('moment');
import {
    RACButton,
    Box,
    Grid,
    List,
    ListItem,
    Typography,
    Card,
    CardContent,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACToggle,
    RACDatePicker,
    TableCell,
    CircularProgress,
    RACModalCard
} from "@rentacenter/racstrap";
// import { scheduleHeader } from "../../../constants/constants";
import { takePaymentStyles } from "./PoDetailStyles/makeStyles";
import { customerStyles } from "./PoDetailStyles/customerStyles";
import { reversePaymentStyle } from "./PoDetailStyles/reversePaymentStyle";
import { agreementDeliveryStyles } from "./PoDetailStyles/agreementDeliveryStyles";
import { agreementGlobalStyles } from "./PoDetailStyles/agreementGlobalStyles";
import { schedulePopupStyles } from "./PoDetailStyles/schedulepopstyles";
import { PoDetailsContext } from "./context/PoDetailsContext";
import { managePoAcimaFn, updateEventStatus, updateStatusCall } from "../../api/user";
import { truncString } from "../../utils/utils";
export default function ScheduleCard() {
    const scheduleHeader = [
        "Item#",
        "Model#",
        "Description",
        "Brand",
        "Cost",
        "Comments",
        "BOL",
        "Status"
    ];
    let { somethingWentWrongOpen, setSomethingWentWrongOpen, data, selectReceivedItem, scheduleReceivedItems, setRescheduler, setSelectReceivedItem, rescheduleId, setRscheduleId, setIsDeliveryReceiptOpen, signInData, setSignInData, setScheduleReceivedItems, isManualOpen, setIsManualOpen, setIsConfirmed, isConfirmed, getLtoData, isConfirmedOpen, setIsConfirmedOpen, setIsHiddenLoader,isSignedByCustomer,setIsSignedByCustomer, acimaPoReceive,setacimaPoReceive } = useContext(PoDetailsContext)

    const [isreopenPopUp, setIsReopenPopup] = useState(false);
    const [poDetailReceivedIdArr, setPoDetailReceivedIdArr] = useState([]);
    const [reopenAppointmentId, setReopenAppointmentId] = useState("");

    const customerclasses = customerStyles();
    const revereseclass = reversePaymentStyle();
    const takepaymentclasses = takePaymentStyles();
    const acimaDeliveryClasses = agreementDeliveryStyles();
    const classes = agreementGlobalStyles();
    const classes1 = schedulePopupStyles();
    useEffect(() => {
        console.log("hello", scheduleReceivedItems);
    }, [scheduleReceivedItems]);

    function manipulateTime(utcTimestamp: any) {
        let timeModified = moment(utcTimestamp).utc().format("HH");
        let time = parseInt(timeModified);
        let timeValidater
        if (time > 12 && time < 24) {
            if (time >= 22) {
                switch (time) {
                    case 22:
                        timeValidater = `${time - 12}:00 PM - ${"0"}:00 AM`;
                        break;
                    case 23:
                        timeValidater = `${time - 12}:00 PM - ${"1"}:00 AM`;
                        break;
                    case 24:
                        timeValidater = `00:00 AM - ${'2'}:00 AM`
                }
            }
            else {
                // let calc = time - 12;
                timeValidater = `${time - 12}:00 - ${time - 10}:00 PM`;
            }
        }
        else {
            if (time >= 10) {
                switch (time) {
                    case 10:
                        timeValidater = `${time}:00 AM - ${"12"}:00 PM`
                        break;
                    case 11:
                        timeValidater = `${time}:00 AM - ${"1"}:00 PM`
                        break;
                    case 12:
                        timeValidater = `${time}:00 AM - ${"1"}:00 PM`
                        break;
                }
            } else {
                timeValidater = `${time}:00 - ${time + 2}:00 AM`
            }
        }
        console.log(timeValidater, "manipulate time in card", utcTimestamp);

        return timeValidater;
    }
    const manipulateAddress = (object: any): any => {
        let address: string = "";
        if (object.addressLine2) {
            address =
                object.addressLine1 +
                ", " +
                object.addressLine2 + ", "
                + object.city +
                ", " +
                object.state +
                " " +
                object.zip;
        }
        else {
            address =
                object.addressLine1 +
                ", "
                + object.city +
                ", " +
                object.state +
                " " +
                object.zip;
        }
        console.log(address, " maniphdibvfei")
        return address;
    };
    {
        console.log(scheduleReceivedItems, " scheduleReceivedItems")
    }
    const renderParentHeaderSchedhuleInfo = () => {
        return (
            <>
                {scheduleHeader.map((column: string, index: number) => (
                    <RACTableCell
                        key={index}
                        className={acimaDeliveryClasses.deliverytype}
                    >
                        {column}
                    </RACTableCell>
                ))}
            </>
        );
    };

    const renderParentContentScheduleInfo = (index: number): any => {
        const receivedItems = scheduleReceivedItems[index];
        console.log(receivedItems, "local receiveditems", index);
        if (receivedItems)
            return (
                <>
                    {/* {receivedItems.deliveryStatus === "Scheduled" || receivedItems.deliveryStatus === "Pending" ? ( */}
                    {receivedItems.itemInformation.map((eachItem: any) => {
                        return (
                            <>
                                <RACTableRow key={index}>
                                    <RACTableCell
                                        className={
                                            classes.formLabel + " " + classes.greyFont + " " + classes.p1
                                        }
                                        style={{ padding: "5px 9px" }}

                                    >
                                        {eachItem.poDetailReceivedId}
                                    </RACTableCell>
                                    <RACTableCell
                                        className={
                                            classes.formLabel + " " + classes.greyFont + " " + classes.p1
                                        }
                                        style={{ padding: "5px 9px" }}

                                    >
                                        {eachItem.model}
                                    </RACTableCell>
                                    <RACTableCell
                                        className={
                                            classes.formLabel + " " + classes.greyFont + " " + classes.p1
                                        }
                                        style={{ padding: "5px 9px" }}
                                        title={eachItem.description ?? ''}
                                    >
                                        {truncString(eachItem.description, 17)}
                                    </RACTableCell>
                                    <RACTableCell
                                        className={
                                            classes.formLabel + " " + classes.greyFont + " " + classes.p1
                                        }
                                        style={{ padding: "5px 9px" }}

                                    >
                                        {eachItem.brand}
                                    </RACTableCell>
                                    <RACTableCell
                                        className={
                                            classes.formLabel + " " + classes.greyFont + " " + classes.p1
                                        }
                                        style={{ padding: "5px 9px" }}

                                    >
                                        {`$${manipulateCost(
                                            eachItem.itemCost
                                        )}`}
                                    </RACTableCell>
                                    <RACTableCell
                                        className={
                                            classes.formLabel + " " + classes.greyFont + " " + classes.p1
                                        }
                                        style={{ padding: "5px 9px" }}

                                    >
                                        {eachItem.comments ? eachItem.comments : "-"}
                                    </RACTableCell>
                                    <RACTableCell
                                        className={
                                            classes.formLabel + " " + classes.greyFont + " " + classes.p1
                                        }
                                        style={{ padding: "5px 9px" }}

                                    >
                                        {eachItem.bol}
                                    </RACTableCell>
                                    <RACTableCell
                                        className={
                                            classes.formLabel + " " + classes.greyFont + " " + classes.p1
                                        }
                                        style={{ padding: "5px 9px" }}
                                    >
                                        {receivedItems.deliveryStatus.toLowerCase() == 'pending' || receivedItems.deliveryStatus.toLowerCase() == 'todo' ? 'Scheduled' : (receivedItems.deliveryStatus.toLowerCase() == "completed" ? "Delivered" : receivedItems.deliveryStatus)}
                                    </RACTableCell>
                                </RACTableRow>
                            </>
                        )
                    })}
                    {/* ) : null} */}
                </>
            );
    };
    function manipulateCost(amount: string): string {
        const cost = Number(amount);
        return cost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /**Cofirm delivery click */
    const confirmDelivery = async () => {
        setIsSignedByCustomer(false);
        setIsHiddenLoader(true)
        let payLoad = {
            "agreementId": "dibshfdkn", // static data
            "isAcimaPo": "Y",
            "acimaCreate": {
                "actionName": "Complete",
                "appointmentId": signInData.appointmentId,
                "storeNumber": window.sessionStorage.getItem('storeNumber')
            }
        }
        const payLoadForm = {
            "purchaseOrderNumber": String(data?.purchaseOrderNumber),
            "poDetailReceivedId": signInData.itemInformation.map((value:any,index:number) => {
                return value.poDetailReceivedId
            })            ,
            "appointmentId": signInData.appointmentId,
        }
        const resp = await updateStatusCall(payLoad);
        const updateManage = await managePoAcimaFn(payLoadForm);
        console.log(updateManage.data,"this is the data")
        await getLtoData();
        setIsHiddenLoader(false)
        setIsConfirmedOpen(true);
        console.log("I am called this is the payload for the data to be delivered for the data", payLoad)
    }
    /**Confirm delivery click */

    const renderScheduleCard = (): any => {
        return scheduleReceivedItems.map((item: any, index: number) => {
            console.log(item, "inside the renderschedule card");
            return (
                <>
                    <Grid
                        item
                        md={12}
                        className={customerclasses.mb100}
                        style={{ marginBottom: "10px" }}
                    >
                        <Card
                            // className={`${classes.card} ${classes.w100}  ${classes.mb3}`}
                            // style={{
                            //     borderRadius: "10px",
                            //     marginTop: "10px",
                            // }}
                            style={{
                                borderRadius: '15px',
                                height: '100',
                                marginTop: '5px',
                                marginLeft: '10px',
                                marginRight: '10px'
                            }}
                        >
                            <CardContent
                                className={takepaymentclasses.p0}
                                // style={{ margin: "0px 20px", borderRadius: "10px" }}
                                style={{
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    paddingTop: '10px'
                                }}
                            >
                                <Grid
                                    item
                                    md={12}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "10px",
                                    }}
                                >
                                    <RACTable
                                        renderTableHead={renderParentHeaderSchedhuleInfo}
                                        className={revereseclass.agrementGridMC}
                                        renderTableContent={() => renderParentContentScheduleInfo(index)}
                                    />
                                </Grid>
                                <Grid style={{ margin: "10px 0px" }}>
                                    <Box sx={{ bgcolor: "#eff4ff", borderRadius: "10px" }}>
                                        <CardContent>
                                            <Grid>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{
                                                            height: "30px",
                                                            width: "40%",
                                                        }}
                                                    >
                                                        <List>
                                                            <ListItem>
                                                                <Typography
                                                                    variant="h3"
                                                                    className={acimaDeliveryClasses.deliverytype}
                                                                >
                                                                    Delivery Type :
                                                                </Typography>
                                                                <Typography
                                                                    className={
                                                                        classes.formLabel +
                                                                        " " +
                                                                        classes.greyFont +
                                                                        " " +
                                                                        classes.p1
                                                                    }
                                                                    style={{ marginLeft: "5%", marginBottom: "2%" }}
                                                                >
                                                                    {scheduleReceivedItems[index].deliveryType}
                                                                </Typography>
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        style={{
                                                            height: "30px",
                                                            marginBottom: "10px",
                                                            // maxWidth: "60%",
                                                            // flexBasis: "40%",
                                                        }}
                                                    >
                                                        <List>
                                                            <ListItem>
                                                                <Typography
                                                                    //   style={{
                                                                    //     width: "100px",
                                                                    //   }}
                                                                    variant="h3"
                                                                    className={acimaDeliveryClasses.deliverytype}
                                                                >
                                                                    Delivery Address:
                                                                </Typography>
                                                                <Typography
                                                                    className={
                                                                        classes.formLabel +
                                                                        " " +
                                                                        classes.greyFont +
                                                                        " " +
                                                                        classes.p1
                                                                    }
                                                                    style={{ marginLeft: "5%", marginBottom: "2%" }}
                                                                >
                                                                    {manipulateAddress(
                                                                        scheduleReceivedItems[index].deliveryAddress
                                                                    )}
                                                                </Typography>
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                </div>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                    }}
                                                >
                                                    <Grid container>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{
                                                            height: "30px",
                                                            width: "40%",
                                                        }}
                                                    >
                                                        <List>
                                                            <ListItem>
                                                                <Typography
                                                                    variant="h3"
                                                                    className={acimaDeliveryClasses.deliverytype}
                                                                >
                                                                    Date :
                                                                </Typography>
                                                                <Typography
                                                                    className={
                                                                        classes.formLabel +
                                                                        " " +
                                                                        classes.greyFont +
                                                                        " " +
                                                                        classes.p1
                                                                    }
                                                                    style={{ marginLeft: "5%", marginBottom: "2%" }}
                                                                >
                                                                    {moment(scheduleReceivedItems[index].deliveryDate).format("MM/DD/YYYY")}
                                                                </Typography>
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{
                                                            height: "30px",
                                                            marginBottom: "10px",
                                                            maxWidth: "20%",
                                                            flexBasis: "40%",
                                                        }}
                                                    >
                                                        <List>
                                                            <ListItem>
                                                                <Typography
                                                                    // style={{
                                                                    //     width: "100px",
                                                                    // }}
                                                                    variant="h3"
                                                                    className={acimaDeliveryClasses.deliverytype}

                                                                >
                                                                    Time:
                                                                </Typography>
                                                                <Typography
                                                                    style={{ marginLeft: "5%", marginBottom: "2%" }}
                                                                    className={
                                                                        classes.formLabel +
                                                                        " " +
                                                                        classes.greyFont +
                                                                        " " +
                                                                        classes.p1
                                                                    }
                                                                >
                                                                    {manipulateTime(scheduleReceivedItems[index].deliveryTime)}
                                                                </Typography>
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        
                                                        xs={3}

                                                        style={{
                                                            height: "30px",
                                                            marginBottom: "10px",
                                                            maxWidth: "20%",
                                                            flexBasis: "40%",

                                                        }}
                                                    >
                                                        <List>
                                                            <ListItem>
                                                                <Typography
                                                                    //   style={{
                                                                    //      width: "100px",
                                                                    //   }}
                                                                    variant="h3"
                                                                    className={acimaDeliveryClasses.deliverytype}
                                                                >

                                                                    #Coworkers Required:
                                                                </Typography>
                                                                <Typography
                                                                    style={{ marginLeft: "5%", marginBottom: "2%" }}
                                                                    className={
                                                                        classes.formLabel +
                                                                        " " +
                                                                        classes.greyFont +
                                                                        " " +
                                                                        classes.p1
                                                                    }
                                                                >
                                                                    {scheduleReceivedItems[index].coworkersReq ? scheduleReceivedItems[index].coworkersReq : "-"}
                                                                </Typography>
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{
                                                            height: "30px",
                                                            marginBottom: "10px",
                                                            maxWidth: "30%",    
                                                            flexBasis: "40%",
                                                        }}
                                                    >
                                                        <List>
                                                            <ListItem>
                                                                <Typography
                                                                    style={{
                                                                        width: "100px",
                                                                    }}
                                                                    variant="h3"
                                                                    className={acimaDeliveryClasses.deliverytype}

                                                                >
                                                                    Instructions:
                                                                </Typography>
                                                                <Typography
                                                                    className={
                                                                        classes.formLabel +
                                                                        " " +
                                                                        classes.greyFont +
                                                                        " " +
                                                                        classes.p1
                                                                    }
                                                                    style={{ marginLeft: "5%", marginBottom: "2%" }}
                                                                >
                                                                    {/* {scheduleReceivedItems[index].instruction
                                                                        ? scheduleReceivedItems[index].instruction
                                                                        : "-"} */}
                                                                    {scheduleReceivedItems[index].instruction ? (<span title={scheduleReceivedItems[index].instruction}>{
                                                                        scheduleReceivedItems[index].instruction.length > 30
                                                                            ? scheduleReceivedItems[index].instruction.substring(0,27) + '...'
                                                                            : scheduleReceivedItems[index].instruction
                                                                    }</span>) :"-"}
                                                                </Typography>
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </CardContent>
                                    </Box>
                                </Grid>
                                {
                                    ((!(scheduleReceivedItems[index].deliveryStatus.toLowerCase() == "completed")) && scheduleReceivedItems[index].deliveryStatus.toLowerCase() !== "cancelled") ? <Grid style={{ margin: "5px" }}>
                                        <RACButton
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            className={acimaDeliveryClasses.footerRight}
                                            onClick={() => {
                                                if (item.isManualSign && !isConfirmed) {
                                                    setIsManualOpen(true);
                                                    setSignInData(item);
                                                } else if (signInData.appointmentId === item.appointmentId && isConfirmed) {
                                                    setSignInData(item);
                                                    setIsSignedByCustomer(true)
                                                    // confirmDelivery();
                                                } else {
                                                    setSignInData(item);
                                                    setIsDeliveryReceiptOpen(true)
                                                }
                                            }}

                                        >

                                            {
                                                console.log(item, "This is the data")}

                                            {console.log(isConfirmed, "===>", item.appointmentId)}

                                            {(item.isManualSign && !isConfirmed) ? `Reprint Delivery Receipt` : (signInData.appointmentId === item.appointmentId && isConfirmed && item.isManualSign) ? `Confirm Delivery` : `Sign and confirm Delivery`}
                                        </RACButton>
                                        <RACButton
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            className={acimaDeliveryClasses.footerRight}
                                            onClick={() => {
                                                setSomethingWentWrongOpen(true);
                                                setRescheduler(index + 1);
                                                // let selectReceivedItemArr = rescheduleId;
                                                // selectReceivedItemArr.push(item.itemInformation[0].poDetailReceivedId);
                                                // setRscheduleId(selectReceivedItemArr)
                                                let selectReceivedItemArr = item.itemInformation.map((value:any,index:any) => {
                                                    return value.poDetailReceivedId
                                                })
                                                setRscheduleId(selectReceivedItemArr)
                                            }}
                                        >
                                            Reschedule
                                        </RACButton>
                                        <RACButton
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            className={acimaDeliveryClasses.footerRight}
                                            onClick={async () => {
                                                let selectReceivedItemArr = []
                                                selectReceivedItemArr = item.itemInformation.map((value: any, index: any) => {

                                                    return value.poDetailReceivedId

                                                })

                                                console.log(selectReceivedItemArr, "the cancel event console");
                                                await setPoDetailReceivedIdArr(selectReceivedItemArr);
                                                acimaPocancelEvent(item.appointmentId, "Cancel")
                                            }}
                                        >
                                            Cancel Event
                                        </RACButton>
                                        <List style={{ display: "inline-block", float: "right", padding: "0%" }}>
                                            <ListItem style={{ display: "flex" }}>
                                                <Grid style={{ margin: "0px", padding: "5px 7px" }}>
                                                    <RACToggle
                                                        //   toggleColor="#71CA2F"
                                                        OnChange={(e) => {
                                                            onChangeOfmannualSignToggle(e, item);
                                                        }}
                                                        checked={item?.isManualSign == true ? true : false}
                                                        toggleColor={"dodgerblue"}
                                                    ></RACToggle>
                                                </Grid>
                                                <Typography
                                                    variant="h4"
                                                    className={acimaDeliveryClasses.deliverytype}
                                                    style={{ padding: "0px", marginTop: "5px", }}
                                                >
                                                    Manual Signature
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </Grid> : (scheduleReceivedItems[index].deliveryStatus.toLowerCase() == "cancelled") ? <Grid style={{ margin: "5px" }}>
                                        <RACButton
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            className={acimaDeliveryClasses.footerRight}
                                            onClick={async() => {
                                                setIsReopenPopup(true);
                                                setReopenAppointmentId(item.appointmentId);
                                                let selectReceivedItemArr = [];
                                                selectReceivedItemArr = item.itemInformation.map((value: any, index: any) => {
                                                    return value.poDetailReceivedId
                                                })
                                                console.log(selectReceivedItemArr, "the injlvndfsjvm");
                                                await setPoDetailReceivedIdArr(selectReceivedItemArr);
                                            }}
                                        >
                                            Reopen Event
                                        </RACButton>
                                    </Grid> : null
                                }

                            </CardContent>
                        </Card>
                    </Grid>
                </>
            );
        });
    };

    /**Cancel event starts here */
    const acimaPocancelEvent = async (appointmentId: any, eventType: any) => {
        setIsHiddenLoader(true);
        const payloadForAppointmentAction: any = {
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            appointmentId: (eventType === 'Cancel') ? appointmentId : reopenAppointmentId,
            actionName: eventType
        }

        console.log(payloadForAppointmentAction, "Cancel eventpayload")
        let updateEventAPICall = await updateEventStatus(payloadForAppointmentAction);
        console.log(updateEventAPICall, "This is the api call response");

        let getPageLoadData = await getLtoData();

        if (eventType != 'Cancel') {
            setReopenAppointmentId("");
        }
        setIsReopenPopup(false);
        setIsHiddenLoader(false);
        let managePoPayload = {
            "purchaseOrderNumber": String(data?.purchaseOrderNumber),
            "poDetailReceivedId": poDetailReceivedIdArr,
            "appointmentId": (eventType === 'Cancel') ? appointmentId : reopenAppointmentId
        }
       await managePoAcimaFn(managePoPayload);
    }
    /**Cancel event ends here*/

    {
        console.log(isConfirmed, "this is the is confiremed")
    }
    //New change for mannual receipt;
    const onChangeOfmannualSignToggle = (e: any, items: any) => {
        try {
            const scheduleReceivedItemsSpread = [...scheduleReceivedItems]

            setIsConfirmed(false)

            for (let values of scheduleReceivedItemsSpread) {
                if (values.appointmentId === items.appointmentId) {
                    values.isManualSign = e.target.checked;
                } else {
                    values.isManualSign = false;
                }
                setScheduleReceivedItems(scheduleReceivedItemsSpread)
            }

            setSignInData(items)

        } catch (error: any) {
            console.log(error.message, "this is the error ")
        }
    }


    //Reopen confirm popup starts here;
    const reopenPopup = () => {
        return (
            <>
                <Grid item md={12}>
                    <Grid item md={12} className={classes.textCenter}>
                        <Typography className={classes.RACPOPMsg}>
                            Are you sure you want to Reopen this event?
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                    >
                        <RACButton
                            variant="outlined"
                            color="primary"
                            className={classes.me2}
                            onClick={() => {
                                setIsReopenPopup(false)
                                setReopenAppointmentId("");
                            }}
                        >
                            No
                        </RACButton>
                        <RACButton
                            variant="contained"
                            color="primary"
                            className={`${classes.me2} ${classes.foc}`}
                            onClick={() => { acimaPocancelEvent(1, 'Reopen') }}
                        >
                            Yes
                        </RACButton>
                    </Grid>
                </Grid>
            </>
        );
    };


        {/* Do you want to schedule popup*/ }
        const isCustomerSignedPopup = () => {
            return (
                <Grid style={{ "textAlign": "center", padding: "0rem 0rem 0rem 0.75rem" }} className={classes.p4}>
                              <Typography className={classes.font24}
                                variant="h6" style={{
                                    textAlign:"center",
                                  "fontWeight": "normal",
                                  color: "#393939",
                                  fontSize: "17px",
                                  fontFamily : 'OpenSans-semibold',
                                  maxWidth: "100%",
                                  alignContent: "center"
                              }}>Did the customer sign the delivery receipt?</Typography>
                              <Grid style={{ "textAlign": "center" }}>
                                  <RACButton
                                      color="primary"
                                      variant="outlined"
                                      size="small"
                                      className={classes.mt4 + " " + classes.p2 + " " + classes.mx2 + " " + classes.px4 + " " + classes.py2}
                                      onClick={() => {
                                        setIsSignedByCustomer(false);
                                      }}
                                  >
                                      No
                                  </RACButton>
                                  <RACButton
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      className={classes.mt4 + " " + classes.p2 + " " + classes.mx2 + " " + classes.px4 + " " + classes.py2}
                                      onClick={() => {
                                        confirmDelivery();
    
                                      }}
                                  >
                                      Yes
                                  </RACButton>
                              </Grid>
                          </Grid>
            )
        }
        {/* Do you want to schedule popup ends here*/ }


    return (scheduleReceivedItems.length != 0 ?
        <>

            <RACModalCard
                isOpen={isSignedByCustomer}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={"10px"}
                onClose={() => {
                    setIsSignedByCustomer(false);
                }
                }
            >
                { isCustomerSignedPopup() }
            </RACModalCard>
            <RACModalCard
                isOpen={isreopenPopUp}
                maxWidth="xs"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => setIsReopenPopup(false)}
                // eslint-disable-next-line react/no-children-prop
                children={reopenPopup()}
            />

            <Typography
                variant="h6"
                className={acimaDeliveryClasses.deliverytype + " " + classes.fontBold + " " + classes.ms2}
                style={{
                    marginTop: '15px',
                    marginBottom: '10px'
                }}
            >
                Scheduled Information
            </Typography >
            {renderScheduleCard()}
        </>
        : null
    )
}